// extracted by mini-css-extract-plugin
export var csHeaderWrapper = "cs-module--cs-header-wrapper--aVJpB";
export var csFeaturedImage = "cs-module--cs-featured-image---TL0i";
export var csContentWrapper = "cs-module--cs-content-wrapper--2n0Ne";
export var addPadding = "cs-module--add-padding--1Ldc2";
export var csProblem = "cs-module--cs-problem--b_CxH";
export var csSynthesis = "cs-module--cs-synthesis--1TF47";
export var csFeedback = "cs-module--cs-feedback--1bGrN";
export var csInfo = "cs-module--cs-info--a8SV3";
export var csProcess = "cs-module--cs-process--fZ9JL";
export var csReflections = "cs-module--cs-reflections--3SHEU";
export var csProcessVisual = "cs-module--cs-process-visual--rMADC";
export var csSynthesisVisual = "cs-module--cs-synthesis-visual--3JIUY";
export var smallHeading = "cs-module--small-heading--3unFD";
export var blockquote = "cs-module--blockquote--3Wf3r";
export var emoji = "cs-module--emoji--30WKl";
export var number = "cs-module--number--2C3BC";
export var csFooter = "cs-module--cs-footer--gphqf";
export var nextProject = "cs-module--next-project--1Ydyo";