import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import * as csStyles from "../../styles/cs.module.css"
import bookGIF from '../../images/casestudy/allwell/book.gif'
import doctorsGIF from '../../images/casestudy/allwell/doctors.gif'
import notificationsGIF from '../../images/casestudy/allwell/notifications.gif'
import registerGIF from '../../images/casestudy/allwell/register.gif'
import walkinGIF from '../../images/casestudy/allwell/walkin.gif'
import watchGIF from '../../images/casestudy/allwell/watch.gif'

export default function AllWell() {
	return (
		<Layout>
			
			<header className={csStyles.csHeaderWrapper} id="top">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-md-8">
							<h1>AllWell Medical App</h1>
							<p>Simplifying doctor visits and appointment scheduling for clinic patients.</p>
						</div>
					</div>
				</div>
				<StaticImage src="../../images/casestudy/allwell/featured-image.jpg" layout="fullWidth" className={csStyles.csFeaturedImage} alt=""/>
			</header>
			
			<main className={csStyles.csContentWrapper}>
				
				<section className={`container-fluid ${csStyles.addPadding}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Overview</h2>
							<p>AllWell Medical is a conceptual iOS/watchOS app that aims to help patients plan their doctor visits quickly and seamlessly. Users can complete patient registrations, book and manage their doctor appointments, view clinic walk-in status, and get timely updates or reminders with just a few taps on their phone or smartwatch.</p>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding} ${csStyles.csInfo} text-center`}>
					<div className="row justify-content-center">
						<div className="col-md-2">
							<h3>My Role</h3>
							<ul className="list-unstyled">
								<li>Interaction Design</li>
								<li>Sketches</li>
								<li>Prototyping</li>
								<li>User Testing</li>
								<li>Visual Design</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h3>Timeline</h3>
							<ul className="list-unstyled">
								<li>3 weeks</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h3>Tools</h3>
							<ul className="list-unstyled">
								<li>Figma</li>
								<li>Balsamiq</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h3>Team</h3>
							<ul className="list-unstyled">
								<li>Elana Chapman</li>
								<li>Justina Lam</li>
								<li>Claudia Szabuniewicz</li>
								<li>Amelia Tan</li>
							</ul>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding} ${csStyles.csProblem}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Problem</h2>
							<p>Administrative staff at medical centers spend hours tending to patients' needs every day. They handle phone requests for appointments from off-site patients and respond to their inquiries, all while attending to patients on-site at the clinic. This inevitably leads to communication inefficiencies, longer wait times, and an overall frustrating experience for both patients and staff.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-9">
							<blockquote className={`blockquote ${csStyles.blockquote}`}>
								How might we offload some of the time-consuming admin tasks performed for off-site patients to better focus on on-site patient care?
							</blockquote>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Goals</h2>
							<p>Our goal was to support clinics in meeting the needs of their off-site patients by designing a mobile app (and a companion watch app) that provides users with greater flexibility and autonomy in planning their doctor visits.</p>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding} ${csStyles.csProcess} text-center`}>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<h2>Process</h2>
							<p>With three weeks to design an entire app, we took a Lean UX approach from the outset. We built a minimum viable product (MVP) initially based on our assumptions and then iterated as necessary based on continuous user feedback.</p>
						</div>
					</div>
					<div className={`row justify-content-center ${csStyles.csProcessVisual}`}>
						<div className="col-md-2">
							<h3><span className={csStyles.emoji}>💡</span><br/><br/>Think</h3>
							<ul className="list-unstyled">
								<li>Business Outcomes</li>
								<li>User Outcomes</li>
								<li>Proto-Persona</li>
								<li>Feature Prioritization</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h3><span className={csStyles.emoji}>🖌</span><br/><br/>Make</h3>
							<ul className="list-unstyled">
								<li>User Flows</li>
								<li>Low Fidelity</li>
								<li>Mid Fidelity</li>
								<li>High Fidelity</li>
							</ul>
						</div>
						<div className="col-md-2">
							<h3><span className={csStyles.emoji}>🔍</span><br/><br/>Check</h3>
							<ul className="list-unstyled">
								<li>Guerrilla Testing</li>
								<li>Usability Testing</li>
							</ul>
						</div>
					</div>
				</section>
				
				<section className={`container-fluid ${csStyles.addPadding}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Generating assumptions</h2>
							<p>We started with a brainstorming session where we defined the problem statement and generated assumptions about the business outcomes and user benefits we are trying to achieve. Our target users were patients who were eager to use digital tools to plan their doctor visits.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/allwell/outcomes.jpg" layout="fullWidth" alt="Business and User Outcomes"/>
								<figcaption className="figure-caption">Business and User Outcomes</figcaption>
							</figure>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Creating a proto-persona</h2>
							<p>To capture our assumptions about our target users, we developed a proto-persona: Asana, a woman in her mid-30s who visits her family physician regularly. Asana kept us focused on user needs as we validated and iterated on our designs.</p>
							<figure className="figure">
								<StaticImage src="../../images/casestudy/allwell/proto-persona.jpg" layout="fullWidth" alt="Proto-Persona: Asana Jones"/>
								<figcaption className="figure-caption">Proto-Persona: Asana Jones</figcaption>
							</figure>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Prioritizing features for the MVP</h2>
							<p>Once we had a good idea of the context in which patients like Asana would use our app, we then brainstormed features that would help them achieve their expected outcomes.</p>
							<p>We shortlisted the following features for the MVP based on what we assumed to be most relevant to off-site patients:</p>
							<ol>
								<li>New Patient Registration</li>
								<li>Appointment Scheduling and Management</li>
								<li>Appointment Reminders</li>
								<li>Doctor Information</li>
								<li>Walk-In Clinic Information</li>
							</ol>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Rapidly sketching wireframes for the app</h2>
							<p>We ran a collaborative design studio where each team member rapidly sketched their own ideas for the selected features on a 6-up sheet. After a few rounds of divergent ideation, we voted for the best ideas and converged on one final set of sketches each for the mobile app and the smartwatch app.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/allwell/mobile-sketches.jpg" layout="fullWidth" alt="Mobile Sketches"/>
								<figcaption className="figure-caption">Mobile App Sketches</figcaption>
							</figure>
							<figure className="figure">
								<StaticImage src="../../images/casestudy/allwell/watch-sketches.jpg" layout="fullWidth" alt="Smartwatch Sketches"/>
								<figcaption className="figure-caption">Smartwatch App Sketches</figcaption>
							</figure>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Getting feedback early and often</h2>
							<p>We created a low-fidelity paper prototype using the sketches and showed them to five people who had experience setting doctor appointments. The goal was to quickly validate the features of the app and to check whether specific functionality worked in the way it was supposed to. We made iterations on the designs based on user feedback and moved on to the next stage.</p>
							<h3 className={csStyles.smallHeading}>Example of iteration:</h3>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/allwell/iteration-example-1.jpg" layout="fullWidth" alt="Iteration based on user feedback"/>
								<figcaption className="figure-caption">Iteration based on user feedback</figcaption>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<p><strong>Pain Point:</strong> Users were confused with the option to choose a family doctor when booking an appointment (they were already assigned a family doctor at registration).</p>
							<p><strong>Design Solution:</strong> Preselect the assigned family doctor and remove the other options.</p>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Making a clickable mid-fi prototype for testing</h2>
							<p>Next, we created a mid-fidelity prototype using Balsamiq, which allowed us to put an interactive solution together and get user feedback on the screen layout, UI controls, and user flows.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/allwell/mobile-midfi.jpg" layout="fullWidth" alt="Mobile App User Flow"/>
								<figcaption className="figure-caption">Mobile App User Flow</figcaption>
							</figure>
							<figure className="figure">
								<StaticImage src="../../images/casestudy/allwell/watch-midfi.jpg" layout="fullWidth" alt="Smartwatch App User Flow"/>
								<figcaption className="figure-caption">Smartwatch App User Flow</figcaption>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<p>We conducted usability tests with five other target users in order to understand:</p>
							<ul>
								<li>What are users' initial and overall impressions of the app?</li>
								<li>Do users understand what they can do in the app?</li>
								<li>Can users easily register themselves, manage their appointments, and get clinic information with this app?</li>
								<li>Do users find the app valuable?</li>
							</ul>
							<p>Based on their feedback, we made multiple iterations of our screens and took them back to our participants for further testing.</p>
							<h3 className={csStyles.smallHeading}>Example of iteration:</h3>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-8">
							<figure className="figure">
								<StaticImage src="../../images/casestudy/allwell/iteration-example-2.jpg" layout="fullWidth" alt="Iteration based on user feedback"/>
								<figcaption className="figure-caption">Iteration based on user feedback</figcaption>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<p><strong>Pain Point:</strong> Users were unsure as to what dates were available when booking appointments.</p>
							<p><strong>Design Solution:</strong> Offer visual cues by disabling and greying out dates when doctor is unavailable.</p>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Refining the prototype with more user feedback</h2>
							<p>We incorporated the feedback received from participants into a hi-fidelity prototype using Figma. At this stage, we directed our efforts to branding, visual design, and compliance with Apple Human Interface Guidelines for both iOS and watchOS.</p>
						</div>
					</div>
				</section>

				<section className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Final Deliverables</h2>
							<p>After multiple rapid and iterative cycles, the following designs were deemed to best meet our target users' needs.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-4 text-center">
							<figure className="figure">
								<img src={walkinGIF} alt="" className="img-fluid" width="75%"/>
							</figure>
						</div>
						<div className="col-md-4 my-auto">
							<h3>Visiting the Walk-In Clinic</h3>
							<p>The Clinic Information screen provides an overview of the current status of the clinic, including hours of operation, estimated walk-in wait time, and a list of doctors on duty.</p>
							<p>This helps both registered and non-registered users decide whether to visit the clinic as a walk-in patient, and they can access this information from the home screen without needing to log in.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-4 my-auto">
							<h3>Registering as a New Patient</h3>
							<p>New patients can opt to register with the clinic by filling out the registration form on the app.</p>
							<p>They can enter their name, contact information, and also create an account to view and manage all their appointment records in one place.</p>
						</div>
						<div className="col-md-4 text-center">
							<figure className="figure">
								<img src={registerGIF} alt="" className="img-fluid" width="75%"/>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-4 text-center">
							<figure className="figure">
								<img src={bookGIF} alt="" className="img-fluid" width="75%"/>
							</figure>
						</div>
						<div className="col-md-4 my-auto">
							<h3>Booking and Managing Appointments</h3>
							<p>Once registered, patients can self-schedule their appointments and make changes or cancellations without waiting to speak with staff.</p>
							<p>Patients can choose their preferred doctor, view their availability, and select a timeslot for the appointment.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-4 my-auto">
							<h3>Accessing Doctors' Information</h3>
							<p>The Doctors screen provides patients with key information about family doctors and specialists working at the clinic.</p>
							<p>They can view the doctors' names, specialties, certifications, years of experience, and whether they are currently accepting new patients.</p>
						</div>
						<div className="col-md-4 text-center">
							<figure className="figure">
								<img src={doctorsGIF} alt="" className="img-fluid" width="75%"/>
							</figure>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-4 text-center">
							<figure className="figure">
								<img src={notificationsGIF} alt="" className="img-fluid" width="75%"/>
							</figure>
						</div>
						<div className="col-md-4 my-auto">
							<h3>Receiving Appointment Reminders and Notifications</h3>
							<p>The Notifications screen provides patients with important status updates about their appointments (confirmations, changes, cancellations).</p>
							<p>Patients are also notified and reminded of upcoming appointments as they draw near.</p>
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-md-4 my-auto">
							<h3>Using the Watch App</h3>
							<p>The watch interface complements the phone interface by allowing quick access to information and tasks that are relevant to users on the go.</p>
							<p>This includes viewing upcoming appointments, receiving reminders, and checking wait times and doctors currently available for walk-in visits.</p>
						</div>
						<div className="col-md-4 text-center">
							<figure className="figure">
								<img src={watchGIF} alt="" className="img-fluid" width="75%"/>
							</figure>
						</div>
					</div>
				</section>

				<section className={`container-fluid ${csStyles.addPadding} ${csStyles.csReflections}`}>
					<div className="row justify-content-center">
						<div className="col-md-6">
							<h2>Reflections</h2>
							<ol>
								<li><p><strong>Early feedback from users leads to better design decisions all around.</strong> Creating ongoing changes to the design based on early and continuous user testing provided us with insights into what was working and what wasn't at each stage of the design process.</p></li>
								<li><p><strong>Designing for a smaller screen forces you to decide what matters most.</strong> This was our first time designing a smartwatch app and it challenged us to prioritize content and features in a way that not only adhered to watchOS guidelines but also ensured a consistent experience across devices.</p></li>
								<li><p><strong>Lean UX is an incredibly useful technique for projects with shorter timeframes.</strong> We dug directly into building a testable prototype based on what we assumed would add value to our target users, and then went back and validated/refuted our assumptions iteratively based on how actual users perceived our designs.</p></li>
							</ol>
						</div>
					</div>
				</section>

			</main>

			<div className={`container ${csStyles.csFooter}`}>
				<div className="row">
					<div className="col-md-6 col-sm-6 col-xs-6">
						<div id="previous">
							<div className="prev-project">
								<h4>↑</h4><a href="#top">Back to Top</a>
							</div>
						</div>
					</div>
					<div className="col-md-6 col-sm-6 col-xs-6">
						<div id="next">
							<div className={csStyles.nextProject}>
								<h4>Next project</h4><Link to="/work/oct">OCT Website</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

		</Layout>
	)
}